import React, { useState, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  FaDownload,
  FaSearchPlus,
  FaSearchMinus,
  FaUndo,
  FaChevronLeft,
  FaChevronRight,
  FaTimes,
} from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useResizeObserver from "../../../../../hooks/useResizeObserver";
import { motion, AnimatePresence } from "framer-motion";

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

interface FileModalProps {
  fileUrl: string;
  onClose: () => void;
  senderName: string;
}

const resizeObserverOptions = {};

const PDFOptions = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const FileModal: React.FC<FileModalProps> = ({ fileUrl, onClose, senderName }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [pdfDimensions, setPdfDimensions] = useState({ width: 0, height: 0 });

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;
    if (entry) {
      const { width, height } = entry.contentRect;
      setPdfDimensions(calculatePdfDimensions(width, height));
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  const calculatePdfDimensions = (containerWidth: number, containerHeight: number) => {
    const maxWidth = Math.min(containerWidth * 0.9, 800); // 90% of container width, max 800px
    const maxHeight = containerHeight * 0.7; // 70% of container height
    const aspectRatio = 1.414; // Typical aspect ratio for A4 paper

    let width = maxWidth;
    let height = width / aspectRatio;

    if (height > maxHeight) {
      height = maxHeight;
      width = height * aspectRatio;
    }

    // Ensure width doesn't exceed maxWidth
    if (width > maxWidth) {
      width = maxWidth;
      height = width / aspectRatio;
    }

    return { width, height };
  };

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }, []);

  const changePage = useCallback((offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }, []);

  const previousPage = useCallback(() => changePage(-1), [changePage]);
  const nextPage = useCallback(() => changePage(1), [changePage]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "document.pdf"; // Change to use a more specific dynamically created name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleZoomIn = () => setScale((prev) => Math.min(prev + 0.1, 3));
  const handleZoomOut = () => setScale((prev) => Math.max(prev - 0.1, 0.5));
  const handleRotate = () => setRotation((prev) => (prev + 90) % 360);

  const loadingIndicator = (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <AiOutlineLoading3Quarters className="mb-4 animate-spin text-4xl text-gray-600" />
      <p className="text-lg font-medium text-gray-700">Loading PDF...</p>
    </div>
  );

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex flex-col overflow-hidden bg-black bg-opacity-30 backdrop-blur-md"
      >
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center">
            <img src="/logo192.png" alt={senderName} className="mr-3 h-8 w-8 rounded-full" />
            <span className="text-lg font-semibold text-white">{senderName}</span>
          </div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={onClose}
            className="rounded-full bg-white bg-opacity-20 p-2 text-white hover:bg-opacity-30"
          >
            <FaTimes size={20} />
          </motion.button>
        </div>

        <div
          ref={setContainerRef}
          className="flex flex-grow items-center justify-center overflow-hidden p-2"
          onClick={onClose}
        >
          <div
            className="relative max-h-[calc(100vh-200px)] max-w-[90vw] overflow-auto rounded-lg bg-white shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <Document
              file={fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              options={PDFOptions}
              loading={loadingIndicator}
            >
              <Page
                key={`page_${pageNumber}`}
                pageNumber={pageNumber}
                width={pdfDimensions.width}
                height={pdfDimensions.height}
                scale={scale}
                rotate={rotation}
                loading={loadingIndicator}
              />
            </Document>
          </div>
        </div>

        <div className="flex justify-center space-x-4 p-4">
          {[
            { icon: FaDownload, action: handleDownload, label: "Download" },
            { icon: FaSearchPlus, action: handleZoomIn, label: "Zoom In" },
            { icon: FaSearchMinus, action: handleZoomOut, label: "Zoom Out" },
            { icon: FaUndo, action: handleRotate, label: "Rotate" },
          ].map(({ icon: Icon, action, label }, index) => (
            <motion.button
              key={index}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={action}
              className="flex items-center rounded-full bg-white bg-opacity-20 p-2 text-white hover:bg-opacity-30 sm:px-4 sm:py-2"
            >
              <Icon size={16} className="sm:mr-2" />
              <span className="hidden text-sm sm:inline">{label}</span>
            </motion.button>
          ))}
        </div>

        {numPages && (
          <div className="absolute bottom-[60px] left-1/2 z-50 flex -translate-x-1/2 transform items-center space-x-4 rounded-full bg-white bg-opacity-20 px-4 py-2 text-white hover:bg-opacity-30">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={previousPage}
              disabled={pageNumber <= 1}
              className="text-white focus:outline-none disabled:text-gray-400"
            >
              <FaChevronLeft />
            </motion.button>
            <span className="text-sm font-medium text-white">
              {pageNumber} / {numPages}
            </span>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={nextPage}
              disabled={pageNumber >= numPages}
              className="text-white focus:outline-none disabled:text-gray-400"
            >
              <FaChevronRight />
            </motion.button>
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default FileModal;
