import React from "react";
import FileDetails from "./FileDetails";
import LinkDetails from "./LinkDetails";

interface CardLargeProps {
  image: string;
  heading: string;
  text: string;
  link: string;
  file?: File;
  linkDetails?: string;
}

export default function CardLarge({
  image,
  heading,
  text,
  link,
  file,
  linkDetails,
}: CardLargeProps) {
  return (
    <a
      href={link}
      className="flex h-[340px] w-full min-w-[349px] flex-col overflow-hidden rounded-bl-xl rounded-br-none rounded-tl-xl rounded-tr-xl no-underline sm:w-[349px]"
    >
      <div className="h-[185px]">
        <img src={image} alt={`${text} card`} className="h-full w-full object-cover" />
      </div>
      <div className="flex h-[155px] flex-col gap-[10px] bg-white p-3">
        <h3 className="line-clamp-2 text-[17px] font-bold leading-5">{heading}</h3>
        <p className="line-clamp-2 text-[17px]">{text}</p>
        {file && <FileDetails file={file} />}
        {linkDetails && <LinkDetails url={linkDetails} />}
      </div>
    </a>
  );
}
