import React from "react";

interface EmbedYouTubeProps {
  videoId: string;
}

export default function EmbedYouTube({ videoId }: EmbedYouTubeProps) {
  const isValidVideoId = /^[a-zA-Z0-9_-]{11}$/.test(videoId);

  if (!isValidVideoId) {
    return <p>! Invalid YouTube Video ID !</p>;
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="flex flex-col items-center">
      <div className="aspect-video w-full">
        <iframe
          width="100%"
          height="100%"
          src={embedUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
