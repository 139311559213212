import React from "react";
import { Message } from "../../types";
import { formatDate } from "../../../../utils/dateFormatter";
import MessageDropdown from "./MessageDropdown";

interface MessageWrapperProps {
  children: React.ReactNode;
  message: Message;
  onDeleteMessage: (id: string) => void;
  isDropdownOpen: boolean;
  onToggleDropdown: () => void;
}

const MessageWrapper: React.FC<MessageWrapperProps> = ({
  children,
  message,
  onDeleteMessage,
  isDropdownOpen,
  onToggleDropdown,
}) => {
  const isCurrentUser = message.sender.name === "AITest"; // Replace with actual current user check

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDeleteMessage(message.id);
  };

  return (
    <div className={`group flex ${isCurrentUser ? "justify-end" : "justify-start"} relative mb-4`}>
      <div
        className={`flex max-w-2xl flex-col items-start ${
          isCurrentUser
            ? "rounded-[13px_13px_0px_13px] bg-[#D0F0E1]"
            : "rounded-[13px_13px_13px_0px] bg-white"
        } overflow-wrap-anywhere break-words shadow`}
      >
        <div className="relative">
          {!isCurrentUser && (message.body || message.item.itemType !== "image") && (
            <p
              className={`m-2 text-sm font-semibold text-blue-500 ${isCurrentUser ? "text-right" : "text-left"}`}
            >
              {message.sender.name}
            </p>
          )}
          <div className="flex items-start justify-between">
            {children}
            {isCurrentUser && (
              <div className="absolute right-1 top-1 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                <MessageDropdown
                  isOpen={isDropdownOpen}
                  onToggle={onToggleDropdown}
                  onDelete={handleDelete}
                />
              </div>
            )}
          </div>

          {message.body && (
            <p
              className={`m-2 justify-end text-right text-xs ${isCurrentUser ? "opacity-70" : "opacity-60"}`}
            >
              {formatDate(new Date(message.time))}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageWrapper;
