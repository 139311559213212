/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LoginEmailDto
 */
export interface LoginEmailDto {
  /**
   * Email address
   * @type {string}
   * @memberof LoginEmailDto
   */
  email: string;
  /**
   * App identifier
   * @type {string}
   * @memberof LoginEmailDto
   */
  appIdentifier: string;
}

/**
 * Check if a given object implements the LoginEmailDto interface.
 */
export function instanceOfLoginEmailDto(value: object): value is LoginEmailDto {
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("appIdentifier" in value) || value["appIdentifier"] === undefined) return false;
  return true;
}

export function LoginEmailDtoFromJSON(json: any): LoginEmailDto {
  return LoginEmailDtoFromJSONTyped(json, false);
}

export function LoginEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginEmailDto {
  if (json == null) {
    return json;
  }
  return {
    email: json["email"],
    appIdentifier: json["appIdentifier"],
  };
}

export function LoginEmailDtoToJSON(json: any): LoginEmailDto {
  return LoginEmailDtoToJSONTyped(json, false);
}

export function LoginEmailDtoToJSONTyped(
  value?: LoginEmailDto | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    email: value["email"],
    appIdentifier: value["appIdentifier"],
  };
}
