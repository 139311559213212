import { Cookies } from "react-cookie";

const cookies = new Cookies();

const TOKEN_KEY = "jwt_token";
const TOKEN_EXPIRY_KEY = "jwt_token_expiry";

export const setToken = (token: string): void => {
  // Set cookie to expire in 7 days
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 7);

  cookies.set(TOKEN_KEY, token, { expires: expiryDate, path: "/" });
  cookies.set(TOKEN_EXPIRY_KEY, expiryDate.getTime().toString(), {
    expires: expiryDate,
    path: "/",
  });
};

export const getToken = (): string | null => {
  const token = cookies.get(TOKEN_KEY);
  const expiry = cookies.get(TOKEN_EXPIRY_KEY);

  if (!token || !expiry) {
    return null;
  }

  if (new Date().getTime() > parseInt(expiry, 10)) {
    // Token has expired
    removeToken();
    return null;
  }

  return token;
};

export const removeToken = (): void => {
  cookies.remove(TOKEN_KEY, { path: "/" });
  cookies.remove(TOKEN_EXPIRY_KEY, { path: "/" });
};

export const isAuthenticated = async (): Promise<boolean> => {
  const token = getToken();
  return !!token;
};

export const refreshToken = async (): Promise<string | null> => {
  const currentToken = getToken();
  if (!currentToken) {
    return null;
  }

  try {
    const response = await fetch("https://devapi.mesensei.com/auth/refresh", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to refresh token");
    }

    const data = await response.json();
    setToken(data.accessToken);
    return data.accessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    removeToken();
    return null;
  }
};
