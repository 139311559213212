import React, { ReactNode, useState } from "react";
import { HiddenItemsNumber } from "../Rect";

interface WrapContainerProps {
  title: string;
  items: ReactNode[];
}

export default function WrapContainer({ title, items }: WrapContainerProps) {
  const [showAll, setShowAll] = useState(false);
  const maxVisibleItems = 6;
  const maxItemsForThreeRows = 12; //We shouldn't allow more than 12 items selected for this view

  const handleShowAll = () => {
    setShowAll(true);
  };

  const visibleItems = showAll
    ? items.slice(0, maxItemsForThreeRows)
    : items.slice(0, maxVisibleItems);
  const overflowCount = items.length - visibleItems.length;

  return (
    <div className="flex h-fit flex-col gap-2">
      <h2 className="text-left text-[20px] text-xl font-extrabold leading-[26px]">{title}</h2>
      <div
        className={`flex flex-wrap items-center gap-2 ${showAll ? "max-h-[calc(3*34px)]" : "max-h-20"} overflow-hidden`}
      >
        {visibleItems.map((item, index) => (
          <div key={index} className="min-w-fit">
            {item}
          </div>
        ))}
        {!showAll && overflowCount > 0 && (
          <HiddenItemsNumber number={overflowCount} onClick={handleShowAll} />
        )}
      </div>
    </div>
  );
}
