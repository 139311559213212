import React from "react";
import { Message } from "../../../../types";
import PDFPreview from "./PDF/PDFPreview";
import DownloadButton from "./DownloadButton";
import FileInfo from "./FileInfo";
import FileModal from "./PDF/PDFModal";
import { pdfjs } from "react-pdf";
import { getFileExtension, getFileSize } from "../../../../../../utils/fileUtils";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

interface FileMessageProps {
  message: Message;
  isCurrentUser: boolean;
}

const FileMessage: React.FC<FileMessageProps> = ({ message, isCurrentUser }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [fileSize, setFileSize] = React.useState<number | null>(null);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const fileExtension = React.useMemo(
    () => getFileExtension(message.item.documentUrl ?? ""),
    [message.item.documentUrl],
  );

  const fileNameWithoutExtension = React.useMemo(() => {
    return message.item.name?.split(".").slice(0, -1).join(".") || message.item.name;
  }, [message.item.name]);

  React.useEffect(() => {
    const fetchFileSize = async () => {
      if (message.item.documentUrl) {
        const size = await getFileSize(message.item.documentUrl);
        setFileSize(size);
      }
    };
    fetchFileSize();
  }, [message.item.documentUrl]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = message.item.documentUrl ?? "";
    link.download = message.item.name ?? "";
    link.click();
  };

  const renderFilePreview = () => {
    const fileType = getFileExtension(message.item.documentUrl ?? "");

    switch (fileType) {
      case "pdf":
        return (
          <PDFPreview
            documentUrl={message.item.documentUrl ?? ""}
            onClick={handleOpenModal}
            isCurrentUser={isCurrentUser}
          />
        );
      default:
        //TODO: Check the positioning of the download button
        return <DownloadButton onClick={handleDownload} />;
    }
  };

  return (
    <div
      className={`flex max-w-[300px] flex-col overflow-hidden rounded-[14px] ${isCurrentUser ? "ml-auto" : "mr-auto"}`}
    >
      {renderFilePreview()}
      <FileInfo
        fileName={fileNameWithoutExtension ?? ""}
        fileExtension={fileExtension}
        fileSize={fileSize}
        isCurrentUser={isCurrentUser}
      />
      {showModal && (
        <FileModal
          fileUrl={message.item.documentUrl ?? ""}
          onClose={handleCloseModal}
          senderName={message.sender.name}
        />
      )}
      {message.body && <p className="m-2 text-sm">{message.body}</p>}
    </div>
  );
};

export default FileMessage;
