import { Message, ThreadResponse } from "../types";

// Simulated API calls
export const fetchMessages = async (): Promise<ThreadResponse> => {
  // In a real app, this would be an API call
  const messages: Message[] = [
    {
      id: "aaf82817-1697-58bc-a6d6-523a80ac77e6",
      status: "read",
      body: "Hello! How can I assist you today?",
      time: "2024-10-05T08:17:15Z",
      item: {
        id: "item-1",
        documentUrl: "",
        name: "Greeting",
        description: "Initial greeting message",
        itemType: "message",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "b171eac3-6d3c-5078-9b6c-61e9e58bbffc",
      status: "read",
      body: "Hi! I have a question about React hooks.",
      time: "2024-10-05T08:17:52Z",
      item: {
        id: "item-2",
        documentUrl: "",
        name: "Question",
        description: "User asked a question about React hooks",
        itemType: "message",
      },
      sender: {
        id: "1f66c690-2e10-5b92-8d47-bb9ddf84314c8393",
        imageUrl:
          "https://media.mesensei.com/public/images/Uwxp993WRJ21gMT5Sdx3Qw-8mkOToPRSw2e9Lb6nCB5mA",
        name: "User",
        description: "User",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "b171eac3-6d3c-5078-9b6c22-61e9e58bbffc",
      status: "read",
      body: "Here is my recording",
      time: "2024-10-05T08:17:52Z",
      item: {
        id: "item-22",
        documentUrl:
          "https://file-examples.com/storage/feb05093336710053a32bc1/2017/11/file_example_MP3_1MG.mp3",
        name: "Question",
        description: "User asked a question about React hooks",
        itemType: "recording",
      },
      sender: {
        id: "1f66c690-2e10-5b92-8d47-bb9ddf8c8393",
        imageUrl:
          "https://media.mesensei.com/public/images/Uwxp993WRJ21gMT5Sdx3Qw-8mkOToPRSw2e9Lb6nCB5mA",
        name: "User",
        description: "User",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "ce8a93fe-8488-5dd5-95a7-7717a77d92c3",
      status: "read",
      body: "Great! I'd be happy to help. What specific question do you have about React hooks?",
      time: "2024-10-05T08:18:29Z",
      item: {
        id: "item-3",
        documentUrl: "",
        name: "Response",
        description: "AI answered the user's question",
        itemType: "message",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "d9b4c2a1-7e56-4f23-8a1d-3c5b9e6f8g2h",
      status: "read",
      body: "I'm trying to understand useEffect. Can you explain it with an example?",
      time: "2024-10-05T08:19:45Z",
      item: {
        id: "item-4",
        documentUrl: "",
        name: "Question",
        description: "User asked a question about useEffect",
        itemType: "message",
      },
      sender: {
        id: "1f66c690-2e10-5b92-8d47-bb9ddf8c8393",
        imageUrl:
          "https://media.mesensei.com/public/images/Uwxp993WRJ21gMT5Sdx3Qw-8mkOToPRSw2e9Lb6nCB5mA",
        name: "User",
        description: "User",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "e7f8g9h0-1i2j-3k4l-5m6n-7o8p9q0r1s2t",
      status: "read",
      body: "Certainly! Here's an example of useEffect:",
      time: "2024-10-05T08:20:30Z",
      item: {
        id: "item-5",
        documentUrl: "",
        name: "Response",
        description: "AI provided an example of useEffect",
        itemType: "message",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "f3g4h5i6-7j8k-9l0m-1n2o-3p4q5r7t8u",
      status: "read",
      body: "",
      time: "2024-10-05T08:21:00Z",
      item: {
        id: "item-6",
        documentUrl: "/logo512.png",
        name: "Image",
        description: "Image from AI",
        itemType: "image",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f17d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "f3g4h5i6-7j8k-9l0m-1n2o-224q5r7t8u",
      status: "read",
      body: "React is a library for building user interfaces.",
      time: "2024-10-05T08:21:00Z",
      item: {
        id: "item-7",
        documentUrl: "/logo512.png",
        name: "Image",
        description: "Image from Konsta Kukkonen",
        itemType: "image",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f17d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "Konsta Kukkonen",
        description: "React Developer",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "f3g4h5i6-7j8k-9l0m-1n2o-3p4q5r6s7t8u",
      status: "read",
      body: "",
      time: "2024-10-05T08:21:00Z",
      item: {
        id: "item-8",
        documentUrl: "/example-images/test-vertical.png",
        name: "Image",
        description: "Image from ATest",
        itemType: "image",
      },
      sender: {
        id: "727cabc0-4286-58e0-008-f1748d22e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "ATest",
        description: "Test User",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "f3g4h5i6-7j8k-sam-1n2o-3p4q5r6s7t8u",
      status: "read",
      body: "This is the react icon ",
      time: "2024-10-05T08:21:00Z",
      item: {
        id: "item-9",
        documentUrl: "/example-images/test-horizontal.png",
        name: "Image",
        description: "Image from AITest",
        itemType: "image",
      },
      sender: {
        id: "727cabc0-4286-58e008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "h7i8j9k0-1l2m-3n4o-5p6q-7r8s9t0u1v2w22",
      status: "read",
      body: "Here's a PDF guide on React hooks for you.",
      time: "2024-10-05T08:23:30Z",
      item: {
        id: "item-10",
        documentUrl: "https://ciencia.ucp.pt/ws/portalfiles/portal/37543939/smc_2012_038.pdf",
        name: "PDF",
        description: "PDF guide on React hooks",
        itemType: "document",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "Tuukka Turunen",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "h7i8j9k0-1l2m-3n4o-5p6q-7r8s9t0u1v2w",
      status: "read",
      body: "Here's a zip file for you.",
      time: "2024-10-05T08:23:30Z",
      item: {
        id: "item-11",
        documentUrl: "example zip file.zip",
        name: "ZIP",
        description: "ZIP file for user",
        itemType: "document",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "h7i8j922-1l2m-3n4o-5p6q-7r8s9t0u1v2w",
      status: "read",
      body: "",
      time: "2024-10-05T08:23:30Z",
      item: {
        id: "item-12",
        documentUrl:
          "https://assets.ctfassets.net/wm1n7oady8a5/6tJdKFW6ukyIE4Y8sSuYo4/86aa1e4178bef579ac8674eefa1f6bc5/A4-booklet-landscape.en.pdf",
        name: "PDF",
        description: "PDF guide on React hooks",
        itemType: "document",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "k2l3m4n5-6o7p-8q9r-0s1t-2u3v4w5x6y7z",
      status: "read",
      body: "And here's another PDF on advanced React patterns.",
      time: "2024-10-05T08:25:00Z",
      item: {
        id: "item-13",
        documentUrl: "https://ciencia.ucp.pt/ws/portalfiles/portal/37543939/smc_2012_038.pdf",
        name: "PDF",
        description: "PDF on advanced React patterns",
        itemType: "document",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "k2l3m4n5-6o7p-8q91-0s1t-2u3v4w5x6y7z",
      status: "read",
      body: "",
      time: "2024-10-05T08:25:00Z",
      item: {
        id: "item-14",
        documentUrl: "https://core.ac.uk/download/pdf/234645196.pdf",
        name: "PDF",
        description: "PDF on advanced React patterns",
        itemType: "document",
      },
      sender: {
        id: "727cabc0-4286-58e0-9008-f1748d6922e5",
        imageUrl:
          "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
        name: "AITest",
        description: "AI Assistant",
        itemType: "user",
      },
      type: "undefined",
    },
    {
      id: "i9j0k1l2-3m4n-5o6p-7q8r-9s0t1u2v3w4x",
      status: "read",
      body: "Thank you so much! This is exactly what I needed.",
      time: "2024-10-05T08:24:45Z",
      item: {
        id: "item-15",
        documentUrl: "",
        name: "Text",
        description: "User thanked AI",
        itemType: "message",
      },
      sender: {
        id: "1f66c690-2e10-5b92-8d47-bb9ddf8c8393",
        imageUrl:
          "https://media.mesensei.com/public/images/Uwxp993WRJ21gMT5Sdx3Qw-8mkOToPRSw2e9Lb6nCB5mA",
        name: "User",
        description: "User",
        itemType: "user",
      },
      type: "undefined",
    },
  ];

  return {
    success: true,
    message: "",
    thread_uuid: "f9afe709-bf0d-5653-9bf7-4ef3aa54c7c2",
    thread_last_message_number: 314015,
    messages: messages,
    unread_messages: 0,
    first_message_number: 314007,
    last_message_number: 314015,
    first_message_time: "2024-10-05T08:17:15Z",
    last_message_time: "2024-10-05T08:24:45Z",
    total_unread_messages: 0,
    total_unread_threads: 0,
    current_workspace_missed_calls: null,
    current_workspace_unread_messages: null,
    total_app_unseen_content: 0,
    other_workspaces_unseen_content: 0,
  };
};

export const sendMessage = async (content: string): Promise<Message> => {
  return {
    id: Date.now().toString(),
    status: "sent",
    body: content,
    time: new Date().toISOString(),
    item: {
      id: `item-${Date.now()}`,
      documentUrl: "",
      name: "User Message",
      description: "Message sent by user",
      itemType: "message",
    },
    sender: {
      id: "1f66c690-2e10-5b92-8d47-bb9ddf8c8393",
      imageUrl:
        "https://media.mesensei.com/public/images/Uwxp993WRJ21gMT5Sdx3Qw-8mkOToPRSw2e9Lb6nCB5mA",
      name: "AITest",
      description: "User",
      itemType: "user",
    },
    type: "undefined",
  };
};
