import React from "react";
import { FaChevronLeft, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface TopBarProps {
  chatName: string;
}

const TopBar: React.FC<TopBarProps> = ({ chatName }) => {
  const navigate = useNavigate();

  return (
    <div className="fixed left-0 right-0 top-0 z-40 flex h-[50px] items-center justify-between bg-[#099e4c] px-4">
      <button onClick={() => navigate(-1)} className="text-white focus:outline-none">
        <FaChevronLeft size={18} />
      </button>
      <h2 className="text-sm font-semibold text-white">{chatName}</h2>
      <button className="text-white focus:outline-none">
        <FaEllipsisV size={18} />
      </button>
    </div>
  );
};

export default TopBar;
