import React from "react";
import { Message } from "../../types";
import MessageWrapper from "./MessageWrapper";
import MessageContent from "./MessageContent/MessageContent";
import { useDropdown } from "../../hooks/useDropdown";

interface Props {
  messages: Message[];
  onDeleteMessage: (id: string) => void;
}

const MessageList: React.FC<Props> = ({ messages, onDeleteMessage }) => {
  const { openId, toggle, close } = useDropdown();

  const handleDeleteMessage = (id: string) => {
    onDeleteMessage(id);
    close(); // Close the dropdown after deleting
  };

  return (
    <div className="p-4">
      {messages.map((message: Message) => {
        const isCurrentUser = message.sender.name === "AITest"; // Replace with actual current user check

        return (
          <MessageWrapper
            key={message.id}
            onDeleteMessage={handleDeleteMessage}
            isDropdownOpen={openId === message.id}
            onToggleDropdown={() => toggle(message.id)}
            message={message}
          >
            <MessageContent message={message} isCurrentUser={isCurrentUser} />
          </MessageWrapper>
        );
      })}
    </div>
  );
};

export default MessageList;
