import React from "react";
import { FaFileAlt } from "react-icons/fa";

interface FileInfoProps {
  fileName: string;
  fileExtension: string;
  fileSize: number | null;
  isCurrentUser: boolean;
}

const FileInfo: React.FC<FileInfoProps> = ({
  fileName,
  fileExtension,
  fileSize,
  isCurrentUser,
}) => {
  const formatFileSize = (size: number | null): string => {
    if (size === null) return "Unknown size";
    const units = ["B", "KB", "MB", "GB", "TB"];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(1)} ${units[i]}`;
  };

  return (
    <div className="m-2 flex flex-col">
      <h3 className={`mt-2 text-lg font-bold ${isCurrentUser ? "text-left" : "text-right"}`}>
        {fileName}
      </h3>
      <div
        className={`mt-1 flex items-center text-xs text-gray-600 ${isCurrentUser ? "justify-start" : "justify-start"}`}
      >
        <FaFileAlt className={isCurrentUser ? "order-2 ml-2" : "mr-2"} />
        <span>
          {fileExtension.toUpperCase()} - {formatFileSize(fileSize)}
        </span>
      </div>
    </div>
  );
};

export default FileInfo;
