import React from "react";

interface DownloadButtonProps {
  onClick: () => void;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ onClick }) => {
  const [downloadState, setDownloadState] = React.useState<"idle" | "downloading" | "completed">(
    "idle",
  );

  const handleClick = () => {
    onClick();
    setDownloadState("downloading");
    setTimeout(() => {
      setDownloadState("completed");
      setTimeout(() => setDownloadState("idle"), 2000);
    }, 2000);
  };

  return (
    <button
      className="mx-4 mt-4 h-16 w-16 transform rounded-lg bg-[#099e4c] p-2 font-bold tracking-widest text-white transition-transform duration-200 ease-in-out hover:scale-[1.05] hover:bg-[#4db77c] focus:outline-none active:scale-[1.05]"
      onClick={handleClick}
    >
      <div className="relative flex items-center justify-center">
        <div className="svg-container">
          {downloadState === "idle" && (
            <svg
              className="download-icon group h-[22px] w-[18px]"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="download-arrow transition-transform duration-200 ease-linear group-hover:translate-y-[3px]"
                d="M13 9L9 13M9 13L5 9M9 13V1"
                stroke="#F2F2F2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 17V18C1 18.7956 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H14C14.7956 21 15.5587 20.6839 16.1213 20.1213C16.6839 19.5587 17 18.7956 17 18V17"
                stroke="#F2F2F2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {downloadState === "downloading" && (
            <div className="download-loader h-4 w-4 animate-spin rounded-full border-2 border-transparent border-t-white"></div>
          )}
          {downloadState === "completed" && (
            <svg
              className="check-svg h-5 w-5 animate-[svgScale_0.5s_ease-in-out]"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.1071 7.9071C15.4976 7.51658 15.4976 6.88341 15.1071 6.49289C14.7165 6.10237 14.0834 6.10237 13.6929 6.49289L8.68568 11.5001L7.10707 9.92146C6.71655 9.53094 6.08338 9.53094 5.69286 9.92146C5.30233 10.312 5.30233 10.9452 5.69286 11.3357L7.97857 13.6214C8.3691 14.0119 9.00226 14.0119 9.39279 13.6214L15.1071 7.9071Z"
                fill="white"
              />
            </svg>
          )}
        </div>
      </div>
    </button>
  );
};

export default DownloadButton;
