import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../(Auth)/AuthProvider";

function Root() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/enter-email");
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
      <h1 className="mb-8 text-4xl font-bold text-blue-600">Welcome to the Home Page</h1>
      <nav className="space-y-4">
        <div className="flex space-x-4">
          <Link to="/chat">
            <button className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
              Go to Chat
            </button>
          </Link>
          <Link to="/content">
            <button className="rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700">
              Go to Content
            </button>
          </Link>
          <Link to="/test">
            <button className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700">
              Go to Test
            </button>
          </Link>
        </div>
        <button
          onClick={handleLogout}
          className="w-full rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
        >
          Logout
        </button>
      </nav>
    </div>
  );
}

export default Root;
