import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { CookiesProvider } from "react-cookie";
import { isAuthenticated, removeToken, setToken, refreshToken } from "../../utils/auth";
import LoadingScreen from "./LoadingScreen";

interface AuthContextType {
  isLoggedIn: boolean;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [cookies, setCookie, removeCookie] = useCookies(["jwt_token"]);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      const authenticated = await isAuthenticated();
      setIsLoggedIn(authenticated);
      setIsLoading(false);
    };
    checkAuth();

    // Set up a timer to refresh the token periodically (e.g., every hour)
    const refreshInterval = setInterval(
      async () => {
        if (isLoggedIn) {
          await refreshToken();
        }
      },
      60 * 60 * 1000,
    ); // 1 hour

    return () => clearInterval(refreshInterval);
  }, []);

  const login = useCallback((token: string) => {
    setToken(token);
    setIsLoggedIn(true);
  }, []);

  const logout = useCallback(() => {
    removeToken();
    setIsLoggedIn(false);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <CookiesProvider>
      <AuthContext.Provider value={{ isLoggedIn, login, logout }}>{children}</AuthContext.Provider>
    </CookiesProvider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
