interface FileDetailsProps {
  file: File;
}

export default function FileDetails({ file }: FileDetailsProps) {
  const fileFormat = file.name.split(".").pop()?.toUpperCase() || "Unknown Format";
  const fileSize = (file.size / (1024 * 1024)).toFixed(1) + " MB";

  return (
    <div className="flex items-center gap-2 text-sm">
      <img src="/fileIcon.svg" alt="File icon" width="12" height="14" />
      <span className="font-semibold">{`${fileFormat} - ${fileSize}`}</span>
    </div>
  );
}
