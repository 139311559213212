import React, { useState, useRef, useEffect } from "react";
import { GoPaperclip } from "react-icons/go";
import { FaRegPaperPlane } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";

interface InputAreaProps {
  onSendMessage: (content: string) => void;
}

const InputArea: React.FC<InputAreaProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
      setFiles([]); // Clear files after sending
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 200)}px`;
    }
  }, [message]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles([...files, ...Array.from(e.target.files)]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit} className="border-t bg-white">
      <div className="mx-auto max-w-3xl">
        {files.length > 0 && (
          <div className="flex flex-wrap gap-2 p-2">
            {files.map((file, index) => (
              <div key={index} className="relative">
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className="h-20 w-20 rounded object-cover"
                  />
                ) : (
                  <div className="flex h-20 w-20 items-center justify-center rounded bg-gray-200">
                    <span className="text-sm">{file.name}</span>
                  </div>
                )}
                <button
                  type="button"
                  onClick={() => handleRemoveFile(index)}
                  className="absolute -right-2 -top-2 rounded-full bg-red-500 p-1 text-white"
                >
                  <FaTimes size={12} />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="m-2 flex items-end rounded-lg bg-gray-100 px-3 py-2">
          <button
            type="button"
            onClick={handleAttachmentClick}
            className="mr-2 p-1 text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <GoPaperclip className="h-6 w-6" />
          </button>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*,.pdf"
            multiple
            onChange={handleFileChange}
            className="hidden"
          />
          <textarea
            ref={textareaRef}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Write a message..."
            className="max-h-[180px] w-full resize-none overflow-y-auto border-none bg-transparent py-1.5 leading-relaxed focus:outline-none focus:ring-0"
            rows={1}
          />

          <button
            type="submit"
            className={`p-1 transition-colors duration-200 focus:outline-none ${
              message.trim() || files.length > 0
                ? "text-blue-500 hover:text-blue-600"
                : "text-gray-300"
            }`}
            disabled={!message.trim() && files.length === 0}
          >
            <FaRegPaperPlane className="h-6 w-6" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default InputArea;
