import React from "react";

interface EmbedSpotifyProps {
  src: string;
}

export default function EmbedSpotify({ src }: EmbedSpotifyProps) {
  return (
    <iframe
      src={src}
      width="100%"
      height="152"
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
      className="w-full rounded-xl"
    ></iframe>
  );
}
