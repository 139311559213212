/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Item
 */
export interface Item {
  /**
   *
   * @type {string}
   * @memberof Item
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  imageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  itemType: ItemItemTypeEnum;
}

/**
 * @export
 */
export const ItemItemTypeEnum = {
  Undefined: "undefined",
  Any: "any",
  App: "app",
  Event: "event",
  Group: "group",
  Post: "post",
  Page: "page",
  User: "user",
  Tag: "tag",
  Chat: "chat",
  Message: "message",
  Image: "image",
  Document: "document",
  Video: "video",
  String: "string",
  Url: "url",
  LoginSession: "login-session",
} as const;
export type ItemItemTypeEnum = (typeof ItemItemTypeEnum)[keyof typeof ItemItemTypeEnum];

/**
 * Check if a given object implements the Item interface.
 */
export function instanceOfItem(value: object): value is Item {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("description" in value) || value["description"] === undefined) return false;
  if (!("itemType" in value) || value["itemType"] === undefined) return false;
  return true;
}

export function ItemFromJSON(json: any): Item {
  return ItemFromJSONTyped(json, false);
}

export function ItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): Item {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    imageUrl: json["imageUrl"] == null ? undefined : json["imageUrl"],
    name: json["name"],
    description: json["description"],
    itemType: json["itemType"],
  };
}

export function ItemToJSON(json: any): Item {
  return ItemToJSONTyped(json, false);
}

export function ItemToJSONTyped(value?: Item | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    imageUrl: value["imageUrl"],
    name: value["name"],
    description: value["description"],
    itemType: value["itemType"],
  };
}
