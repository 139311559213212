import React from "react";

interface RowProps {
  image: string;
  heading: string;
  text: string;
  link: string;
  isOnline: boolean;
  isMentor: boolean;
}

export default function Row({ image, heading, text, link, isOnline, isMentor }: RowProps) {
  return (
    <a href={link} className="flex h-[72px] w-full flex-row gap-2 no-underline sm:w-[375px]">
      <div className="relative m-1 flex h-16 min-w-16 items-center justify-center">
        <img
          src={image}
          alt={text}
          className="absolute z-10 h-14 w-14 rounded-full object-cover ring-2 ring-white ring-offset-0"
        />
        {isMentor && (
          <div className="relative h-16 w-16 rounded-full bg-gradient-to-b from-[#01BABA] to-[#68B810]"></div>
        )}
        {isOnline && (
          <div className="absolute bottom-0 right-0 z-20 mb-1 h-3.5 w-3.5 rounded-full bg-[#7ED321] ring-1 ring-white"></div>
        )}
      </div>

      <div className="flex w-full flex-col">
        <h3 className="line-clamp-2 text-[17px] font-bold leading-[1.38]">{heading}</h3>
        <p className="line-clamp-2 text-[17px]">{text}</p>
      </div>
    </a>
  );
}
