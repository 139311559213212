/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ContentContainer } from "./ContentContainer";
import {
  ContentContainerFromJSON,
  ContentContainerFromJSONTyped,
  ContentContainerToJSON,
  ContentContainerToJSONTyped,
} from "./ContentContainer";

/**
 *
 * @export
 * @interface ContentArea
 */
export interface ContentArea {
  /**
   *
   * @type {string}
   * @memberof ContentArea
   */
  id: string;
  /**
   *
   * @type {Array<ContentContainer>}
   * @memberof ContentArea
   */
  contentContainers: Array<ContentContainer>;
}

/**
 * Check if a given object implements the ContentArea interface.
 */
export function instanceOfContentArea(value: object): value is ContentArea {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("contentContainers" in value) || value["contentContainers"] === undefined) return false;
  return true;
}

export function ContentAreaFromJSON(json: any): ContentArea {
  return ContentAreaFromJSONTyped(json, false);
}

export function ContentAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentArea {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    contentContainers: (json["contentContainers"] as Array<any>).map(ContentContainerFromJSON),
  };
}

export function ContentAreaToJSON(json: any): ContentArea {
  return ContentAreaToJSONTyped(json, false);
}

export function ContentAreaToJSONTyped(
  value?: ContentArea | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    contentContainers: (value["contentContainers"] as Array<any>).map(ContentContainerToJSON),
  };
}
