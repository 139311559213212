/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Item } from "./Item";
import { ItemFromJSON, ItemFromJSONTyped, ItemToJSON, ItemToJSONTyped } from "./Item";

/**
 *
 * @export
 * @interface ContentContainer
 */
export interface ContentContainer {
  /**
   *
   * @type {string}
   * @memberof ContentContainer
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ContentContainer
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ContentContainer
   */
  description: string;
  /**
   *
   * @type {Array<Item>}
   * @memberof ContentContainer
   */
  items: Array<Item>;
  /**
   *
   * @type {string}
   * @memberof ContentContainer
   */
  type: ContentContainerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ContentContainer
   */
  elementType: ContentContainerElementTypeEnum;
}

/**
 * @export
 */
export const ContentContainerTypeEnum = {
  Horizontal: "horizontal",
  Vertical: "vertical",
  Wrap: "wrap",
} as const;
export type ContentContainerTypeEnum =
  (typeof ContentContainerTypeEnum)[keyof typeof ContentContainerTypeEnum];

/**
 * @export
 */
export const ContentContainerElementTypeEnum = {
  CardSmall: "card-small",
  CardMedium: "card-medium",
  CardLarge: "card-large",
  CardWide: "card-wide",
  ImageWide: "image-wide",
  TextRich: "text-rich",
  Accordion: "accordion",
  Rect: "rect",
  Row: "row",
  Chip: "chip",
  VideoYoutube: "video-youtube",
  VideoHosted: "video-hosted",
} as const;
export type ContentContainerElementTypeEnum =
  (typeof ContentContainerElementTypeEnum)[keyof typeof ContentContainerElementTypeEnum];

/**
 * Check if a given object implements the ContentContainer interface.
 */
export function instanceOfContentContainer(value: object): value is ContentContainer {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("description" in value) || value["description"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  if (!("type" in value) || value["type"] === undefined) return false;
  if (!("elementType" in value) || value["elementType"] === undefined) return false;
  return true;
}

export function ContentContainerFromJSON(json: any): ContentContainer {
  return ContentContainerFromJSONTyped(json, false);
}

export function ContentContainerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContentContainer {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    description: json["description"],
    items: (json["items"] as Array<any>).map(ItemFromJSON),
    type: json["type"],
    elementType: json["elementType"],
  };
}

export function ContentContainerToJSON(json: any): ContentContainer {
  return ContentContainerToJSONTyped(json, false);
}

export function ContentContainerToJSONTyped(
  value?: ContentContainer | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
    description: value["description"],
    items: (value["items"] as Array<any>).map(ItemToJSON),
    type: value["type"],
    elementType: value["elementType"],
  };
}
