import React, { useState } from "react";

interface TextNavItem {
  name: string;
}

interface ComponentNavItem {
  components: JSX.Element | JSX.Element[];
}

type NavItem = TextNavItem | ComponentNavItem;

interface TabContainerProps {
  tabs: {
    id: string;
    name: string;
    navItems: NavItem[];
  }[];
}

interface NavigationItemProps {
  name: string;
}

export function NavigationItem({ name }: NavigationItemProps) {
  return <div className="text-gray-700">{name}</div>;
}

export default function TabContainer(props: TabContainerProps) {
  const [activeTab, setActiveTab] = useState<string>(props.tabs[0].id);

  const currentNavItems = props.tabs.find((tab) => tab.id === activeTab)?.navItems || [];

  return (
    <div className="">
      <div className="scrollbar-hide border-default-grayx -mx-3 flex snap-x snap-mandatory flex-nowrap space-x-4 overflow-x-scroll border-b-2 px-3 pt-2">
        {props.tabs.map((tab) => (
          <button
            aria-selected
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`relative ${activeTab === tab.id ? "absolute border-b-2 border-default-gray opacity-100" : "pb-0.5 opacity-70"}`}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="py-2">
        {currentNavItems.length > 0 &&
          currentNavItems.map((item, index) =>
            "name" in item ? (
              <NavigationItem key={index} name={item.name} />
            ) : (
              <div className="flex flex-col gap-4" key={index}>
                {item.components}
              </div>
            ),
          )}
      </div>
    </div>
  );
}
