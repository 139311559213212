/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LoginCodeDto
 */
export interface LoginCodeDto {
  /**
   * Login code entered by the user.
   * @type {string}
   * @memberof LoginCodeDto
   */
  loginCode: string;
  /**
   * Code token returned by API /login/email or /login/phone.
   * @type {string}
   * @memberof LoginCodeDto
   */
  codeToken: string;
}

/**
 * Check if a given object implements the LoginCodeDto interface.
 */
export function instanceOfLoginCodeDto(value: object): value is LoginCodeDto {
  if (!("loginCode" in value) || value["loginCode"] === undefined) return false;
  if (!("codeToken" in value) || value["codeToken"] === undefined) return false;
  return true;
}

export function LoginCodeDtoFromJSON(json: any): LoginCodeDto {
  return LoginCodeDtoFromJSONTyped(json, false);
}

export function LoginCodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginCodeDto {
  if (json == null) {
    return json;
  }
  return {
    loginCode: json["loginCode"],
    codeToken: json["codeToken"],
  };
}

export function LoginCodeDtoToJSON(json: any): LoginCodeDto {
  return LoginCodeDtoToJSONTyped(json, false);
}

export function LoginCodeDtoToJSONTyped(
  value?: LoginCodeDto | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    loginCode: value["loginCode"],
    codeToken: value["codeToken"],
  };
}
