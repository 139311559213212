/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LoginCodeResponse
 */
export interface LoginCodeResponse {
  /**
   * Access token (JWT) used in API calls. Access token must be used in header "Authorization: Bearer [accessToken]"
   * @type {string}
   * @memberof LoginCodeResponse
   */
  accessToken: string;
}

/**
 * Check if a given object implements the LoginCodeResponse interface.
 */
export function instanceOfLoginCodeResponse(value: object): value is LoginCodeResponse {
  if (!("accessToken" in value) || value["accessToken"] === undefined) return false;
  return true;
}

export function LoginCodeResponseFromJSON(json: any): LoginCodeResponse {
  return LoginCodeResponseFromJSONTyped(json, false);
}

export function LoginCodeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoginCodeResponse {
  if (json == null) {
    return json;
  }
  return {
    accessToken: json["accessToken"],
  };
}

export function LoginCodeResponseToJSON(json: any): LoginCodeResponse {
  return LoginCodeResponseToJSONTyped(json, false);
}

export function LoginCodeResponseToJSONTyped(
  value?: LoginCodeResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    accessToken: value["accessToken"],
  };
}
