import React from "react";
import { Message } from "../../../types";
import TextMessage from "./TextMessage";
import ImageMessage from "./ImageMessage/ImageMessage";
import FileMessage from "./DocumentMessage/DocumentMessage";

interface MessageContentProps {
  message: Message;
  isCurrentUser: boolean;
}

const MessageContent: React.FC<MessageContentProps> = ({ message, isCurrentUser }) => {
  switch (message.item.itemType) {
    case "message":
      return <TextMessage message={message} isCurrentUser={isCurrentUser} />;
    case "image":
      return <ImageMessage message={message} isCurrentUser={isCurrentUser} />;
    case "document":
      return <FileMessage message={message} isCurrentUser={isCurrentUser} />;
    case "recording":
      return <FileMessage message={message} isCurrentUser={isCurrentUser} />;
    default:
      return <p className="text-sm">Unsupported message type</p>;
  }
};

export default MessageContent;
