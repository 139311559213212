import React from "react";
import { FaChevronDown, FaTrash } from "react-icons/fa";

interface MessageDropdownProps {
  isOpen: boolean;
  onToggle: () => void;
  onDelete: (e: React.MouseEvent) => void;
}

const MessageDropdown: React.FC<MessageDropdownProps> = ({ isOpen, onToggle, onDelete }) => {
  return (
    <div className="dropdown relative z-10 h-4 w-4 self-center rounded-md border border-gray-300 bg-white shadow-md">
      <button
        onClick={onToggle}
        className="absolute inset-0 flex items-center justify-center text-gray-500 transition-opacity duration-200 hover:text-gray-700 focus:outline-none group-hover:opacity-100"
      >
        <FaChevronDown className="h-3 w-3" />
      </button>
      {isOpen && (
        <div className="absolute right-0 z-20 mt-4 w-48 rounded-md bg-white shadow-lg duration-300 hover:bg-gray-100">
          <button
            onClick={onDelete}
            className="flex w-full items-center px-4 py-2 text-sm text-gray-700"
          >
            <FaTrash className="mr-2" /> Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default MessageDropdown;
