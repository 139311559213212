import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { Page, PagesApi, User } from "../../apiClient/generated";
import { API_CONFIG } from "../../apiClient/config";
import { isAuthenticated } from "../../utils/auth";
import CardLarge from "../Content/components/CardLarge";
import CardSmall from "../Content/components/CardSmall";
import HorizontalContainer from "../Content/components/containers/HorizontalContainer";

const BASE_URL = "https://devapi.mesensei.com";

export interface TempApp {
  identifier: string;
  id: string;
  name: string;
}

const TestContainer: React.FC = () => {
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const [user] = useState<User | undefined>(undefined);
  const [app, setApp] = useState<TempApp | undefined>(undefined);
  const [page, setPage] = useState<Page | undefined>(undefined);
  const [isAuthToken, setIsAuthToken] = useState<boolean>(false);

  const [pingEvents, setPingEvents] = useState<string[]>([]);
  const [messageEvents, setMessageEvents] = useState<string[]>([]);

  const pagesApi = new PagesApi(API_CONFIG);

  useEffect(() => {
    pagesApi.findOne({ id: "48b2233c-2409-47b6-942d-e8225660ecea" }).then((page) => {
      setPage(page);
    });

    fetch("https://devapi.mesensei.com/apps/marsu", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApp(data);
      });

    isAuthenticated().then((auth) => {
      setIsAuthToken(auth);
    });

    const socket = io(BASE_URL /*, { transports: ['websocket', 'polling'] }*/);

    const onConnect = () => {
      setIsSocketConnected(true);
    };

    const onDisconnect = () => {
      setIsSocketConnected(false);
    };

    const onPingEvent = (event: string) => {
      setPingEvents((events) => [...events, event]);
    };

    const onMessageEvent = (event: string) => {
      setMessageEvents((events) => [...events, event]);
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", onMessageEvent);
    socket.on("ping", onPingEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("message", onMessageEvent);
      socket.off("ping", onPingEvent);
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <div className="flex flex-col gap-4 p-3">
        Some new content testing....
        <HorizontalContainer
          title="H2 Some People"
          items={[
            <CardSmall
              image={"/coffeeImagePlaceholder.png"}
              text={"John Smith"}
              link={"/content"}
            />,
            <CardSmall image={"/coffeeImagePlaceholder.png"} text={"John Doe"} link={"/content"} />,
            <CardSmall
              image={"/coffeeImagePlaceholder.png"}
              text={"John Black"}
              link={"/content"}
            />,
            <CardSmall image={"/coffeeImagePlaceholder.png"} text={"John Doe"} link={"/content"} />,
            <CardSmall
              image={"/coffeeImagePlaceholder.png"}
              text={"John Black"}
              link={"/content"}
            />,
          ]}
        />
        <iframe
          src="https://open.spotify.com/embed/album/2tUPxUNDhNmoeWlrFdo3Vk?utm_source=generator&theme=0"
          width="100%"
          height="152"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
        <CardLarge
          image={"/imagePlaceholder.png"}
          heading="H3 title 20 Tips to Improve your Typography Skills"
          text="Body At the core of innovation is creativity. It is the ability to imagine, change, and believe in a different reality."
          link={"/content"}
        />
      </div>

      <b>API:</b>
      <div>Status: {app ? "OK" : "Error"}</div>
      <br />
      <b>OpenAPI apiClient:</b>
      <div>Status: {page ? "OK" : "Error"}</div>
      <br />
      <b>App details:</b>
      <div>Identifier: {app ? app.identifier : "-"}</div>
      <div>Name: {app ? app.name : "-"}</div>
      <div>id: {app ? app.id : "-"}</div>
      <br />
      <b>User details:</b>
      <div>Is authenticated: {isAuthToken ? "YES" : "NO"}</div>
      <div>name: {user ? user.name : "-"}</div>
      <div>id: {user ? user.id : "-"}</div>
      <br />
      <b>socket.io:</b>
      <div>Socket connected: {isSocketConnected ? "YES" : "NO"}</div>
      <div>Ping events received: {pingEvents.length}</div>
      <div>Message events received: {messageEvents.length}</div>
      <br />
      <div>Message events:</div>
      <ul>
        {messageEvents.map((event, index) => (
          <li key={index}>{event}</li>
        ))}
      </ul>
      <br />
    </div>
  );
};

export default TestContainer;
