import React from "react";

interface ImageWideProps {
  src: string;
  alt: string;
  caption: string;
}

export default function ImageWide({ src, alt, caption }: ImageWideProps) {
  return (
    <div className="flex w-full flex-col items-center gap-4">
      <img src={src} alt={alt} className="w-full object-cover" />
      <p className="italiс">{caption}</p>
    </div>
  );
}
