import React, { useRef, useEffect } from "react";
import { useChat } from "./hooks/useChat";
import MessageList from "./components/Message/MessageList";
import InputArea from "./components/InputArea";
import TopBar from "./components/TopBar";

const ChatContainer: React.FC = () => {
  const { threadResponse, addMessage, deleteMessage, error } = useChat();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [threadResponse?.messages]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!threadResponse) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen flex-col bg-gray-50">
      <TopBar chatName="Chat" />
      <div className="mt-[50px] flex-grow overflow-y-auto">
        <div className="mx-auto w-full max-w-3xl">
          <MessageList messages={threadResponse.messages} onDeleteMessage={deleteMessage} />
          <div ref={messagesEndRef} />
        </div>
      </div>
      <InputArea onSendMessage={addMessage} />
    </div>
  );
};

export default ChatContainer;
