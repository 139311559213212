import React from "react";
import { Document, Page } from "react-pdf";

interface PDFPreviewProps {
  documentUrl: string;
  onClick: () => void;
  isCurrentUser: boolean;
}

const PDFPreview: React.FC<PDFPreviewProps> = ({ documentUrl, onClick, isCurrentUser }) => (
  <div
    className={`cursor-pointer overflow-hidden ${isCurrentUser ? "rounded-[14px_14px_0px_0px]" : "rounded-[0px_14px_0px_0px]"} border`}
    onClick={onClick}
  >
    <Document file={documentUrl} className="w-full">
      <Page pageNumber={1} width={300} />
    </Document>
  </div>
);

export default PDFPreview;
