/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ContentArea } from "./ContentArea";
import {
  ContentAreaFromJSON,
  ContentAreaFromJSONTyped,
  ContentAreaToJSON,
  ContentAreaToJSONTyped,
} from "./ContentArea";

/**
 *
 * @export
 * @interface Page
 */
export interface Page {
  /**
   *
   * @type {string}
   * @memberof Page
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Page
   */
  imageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Page
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Page
   */
  description: string;
  /**
   * Content of the page.
   * @type {ContentArea}
   * @memberof Page
   */
  contentArea: ContentArea;
}

/**
 * Check if a given object implements the Page interface.
 */
export function instanceOfPage(value: object): value is Page {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("description" in value) || value["description"] === undefined) return false;
  if (!("contentArea" in value) || value["contentArea"] === undefined) return false;
  return true;
}

export function PageFromJSON(json: any): Page {
  return PageFromJSONTyped(json, false);
}

export function PageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Page {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    imageUrl: json["imageUrl"] == null ? undefined : json["imageUrl"],
    name: json["name"],
    description: json["description"],
    contentArea: ContentAreaFromJSON(json["contentArea"]),
  };
}

export function PageToJSON(json: any): Page {
  return PageToJSONTyped(json, false);
}

export function PageToJSONTyped(value?: Page | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    imageUrl: value["imageUrl"],
    name: value["name"],
    description: value["description"],
    contentArea: ContentAreaToJSON(value["contentArea"]),
  };
}
