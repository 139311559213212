import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      !isLoggedIn &&
      location.pathname !== "/enter-email" &&
      location.pathname !== "/login-code"
    ) {
      navigate("/enter-email");
    }
  }, [isLoggedIn, navigate, location.pathname]);

  return <>{children}</>;
};

export default AuthWrapper;
