import React from "react";

interface CardSmallProps {
  image: string;
  text: string;
  link: string;
}

export default function CardSmall({ image, text, link }: CardSmallProps) {
  return (
    <a href={link} className="flex flex-col gap-1.5 overflow-hidden no-underline">
      <img
        src={image}
        alt={`${text} card`}
        className="h-[94px] w-[94px] rounded-bl-xl rounded-br-none rounded-tl-xl rounded-tr-xl object-cover"
      />
      <p className="line-clamp-2 max-w-[94px] text-[17px] font-bold">{text}</p>
    </a>
  );
}
