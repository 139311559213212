/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LoginEmailResponse
 */
export interface LoginEmailResponse {
  /**
   * The code token must be used when verifying login code.
   * @type {string}
   * @memberof LoginEmailResponse
   */
  codeToken: string;
}

/**
 * Check if a given object implements the LoginEmailResponse interface.
 */
export function instanceOfLoginEmailResponse(value: object): value is LoginEmailResponse {
  if (!("codeToken" in value) || value["codeToken"] === undefined) return false;
  return true;
}

export function LoginEmailResponseFromJSON(json: any): LoginEmailResponse {
  return LoginEmailResponseFromJSONTyped(json, false);
}

export function LoginEmailResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoginEmailResponse {
  if (json == null) {
    return json;
  }
  return {
    codeToken: json["codeToken"],
  };
}

export function LoginEmailResponseToJSON(json: any): LoginEmailResponse {
  return LoginEmailResponseToJSONTyped(json, false);
}

export function LoginEmailResponseToJSONTyped(
  value?: LoginEmailResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    codeToken: value["codeToken"],
  };
}
