import React, { ReactNode } from "react";

interface VerticalContainerProps {
  title: string;
  items: ReactNode[];
}

export default function VerticalContainer({ title, items }: VerticalContainerProps) {
  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-left text-[20px] text-xl font-extrabold leading-[26px]">{title}</h2>
      <div className="scrollbar-hide flex w-full snap-x snap-mandatory flex-row space-x-2 overflow-x-auto">
        {items.map((item, index) => (
          <div key={index} className="min-w-fit">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}
