import React from "react";

interface CardWideProps {
  image: string;
  heading: string;
  link: string;
}

export default function CardWide({ image, heading, link }: CardWideProps) {
  return (
    <a
      href={link}
      className="flex h-[117px] w-full min-w-[349px] flex-row overflow-hidden rounded-bl-xl rounded-br-none rounded-tl-xl rounded-tr-xl no-underline sm:w-[349px]"
    >
      <div className="w-[139px]">
        <img src={image} alt={`${heading} card`} className="h-full w-full object-cover" />
      </div>
      <div className="w-full bg-white p-3 sm:w-[210px]">
        <h3 className="line-clamp-4 text-[17px] font-bold leading-[1.38]">{heading}</h3>
      </div>
    </a>
  );
}
