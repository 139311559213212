import React, { useState, useRef, useEffect } from "react";
import { Message } from "../../../../types";
import ImageModal from "./ImageModal";

interface ImageMessageProps {
  message: Message;
  isCurrentUser: boolean;
}

const ImageMessage: React.FC<ImageMessageProps> = ({ message, isCurrentUser }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [skeletonSize, setSkeletonSize] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const img = new Image();
      img.src = message.item.documentUrl ?? "";
      img.onload = () => {
        const containerWidth = containerRef.current?.clientWidth || 0;
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        let width = Math.min(img.naturalWidth, containerWidth);
        let height = width / aspectRatio;

        // Limit height to a maximum of 300px
        if (height > 300) {
          height = 300;
          width = height * aspectRatio;
        }

        setSkeletonSize({ width, height });
      };
    }
  }, [message]);

  const handleImageLoad = () => setImageLoaded(true);
  const handleImageError = () => setImageError(true);
  const handleImageClick = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div ref={containerRef}>
      <div className="relative">
        {!imageLoaded && !imageError && (
          <div
            className="animate-pulse rounded-lg bg-gray-200"
            style={{
              width: `${skeletonSize.width}px`,
              height: `${skeletonSize.height}px`,
              maxWidth: "100%",
              maxHeight: "300px",
            }}
          />
        )}
        {!imageError && (
          <div
            className={`overflow-hidden ${
              message.body
                ? "rounded-t-lg"
                : isCurrentUser
                  ? "rounded-[13px_13px_0px_13px]"
                  : "rounded-[13px_13px_13px_0px]"
            }`}
          >
            <img
              src={message.item.documentUrl}
              alt={`From ${message.sender.name}`}
              className={`h-auto max-h-[300px] w-full max-w-full cursor-pointer bg-white transition-opacity duration-300 ${
                imageLoaded ? "opacity-100" : "absolute left-0 top-0 opacity-0"
              }`}
              style={{
                objectFit: "cover",
              }}
              onLoad={handleImageLoad}
              onError={handleImageError}
              onClick={handleImageClick}
              loading="lazy"
            />
          </div>
        )}
      </div>
      {imageError && (
        <div className="flex h-48 w-full items-center justify-center rounded-lg bg-gray-200">
          <p className="text-sm text-gray-500">Failed to load image</p>
        </div>
      )}
      {showModal && (
        <ImageModal
          imageUrl={message.item.documentUrl ?? ""}
          alt={`From ${message.sender.name}`}
          onClose={handleCloseModal}
          senderName={message.sender.name}
          senderIcon={message.sender.imageUrl ?? ""}
        />
      )}
      {message.body && <p className="mx-2 mt-2 whitespace-pre-wrap text-sm">{message.body}</p>}
    </div>
  );
};

export default ImageMessage;
