import Accordion from "./components/Accordion";
import CardLarge from "./components/CardLarge";
import CardMedium from "./components/CardMedium";
import CardSmall from "./components/CardSmall";
import CardWide from "./components/CardWide";
import HorizontalContainer from "./components/containers/HorizontalContainer";
import TabContainer from "./components/containers/TabContainer";
import VerticalContainer from "./components/containers/VerticalContainer";
import WrapContainer from "./components/containers/WrapContainer";
import ImageWide from "./components/ImageWide";
import Rect from "./components/Rect";
import Row from "./components/Row";
import TextRich from "./components/TextRich";
import EmbedYoutube from "./components/EmbedYoutube";
import EmbedSpotify from "./components/EmbedSpotify";

const Content = () => {
  const accordionData = [
    {
      heading: "Accordion title 1",
      content: "Content for the first accordion item goes here",
    },
    {
      heading: "Accordion title 2",
      content:
        "Content for the second accordion item goes here Content for the second accordion item goes here Content for the second accordion item goes here",
    },
    {
      heading: "Accordion title 3",
      content:
        "Content for the accordion third item Content for the accordion third item Lorem ipsum Eeron vielä ollessa äitinsä helmoissa kotona, kuusi vanhinta veljestä varasti naapurista kananmunia ja karkasi sitten metsään. Karkulaisten elämä metsässä kesti muutaman päivän ennen kuin äiti apujoukkoineen löysi heidät.  Seurasi kova löylytys.",
    },
  ];
  const mockPdfFile = new File(["PDF content"], "document.pdf", { type: "application/pdf" });
  const mockImageFile = new File(["Image content"], "image.png", { type: "image/png" });
  const mockLargeFile = new File(["Large content".repeat(1024 * 1024)], "large-document.txt", {
    type: "text/plain",
  });

  const markdownContent = `
## H2 Some Title
Some text with links to [Wikipedia](https://en.wikipedia.org/) and [Youtube](https://www.youtube.com/). Here is **bold text** and *italicized text*, and insert something in code format \`https://api.mesensei.com\` for developers. Add emojis 🌍🚀 :smile: and :+1: to see how these work.

New paragraph starts. Long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text long text. 

We could also have links or references to content in the app, like user [@Tuukka Ylälahti](/user/9c8b7aa2-9659-550c-bd45-2113bfff93a9) or a tag [#Software Testing](/tag/934df741-8dde-5c13-9d1a-ad72e167fa53).

## H2 Some Other Title
- bullet 1
- bullet 2 with [Youtube](https://www.youtube.com/) link
- bullet 3

1. Ordered list: First item
2. Ordered list: Second item
3. Ordered list: Third item

Final words and some JSON example:

\`\`\`
{
  "firstName": "John",
  "lastName": "Smith",
  "age": 25
}
\`\`\`
`;

  const tabs = [
    {
      id: "home",
      name: "Home",
      navItems: [
        {
          components: [
            <WrapContainer
              title="Topics I know about"
              items={[
                <Rect text="Marketing"></Rect>,
                <Rect text="Research"></Rect>,
                <Rect text="Development"></Rect>,
                <Rect text="Marketing"></Rect>,
                <Rect text="AI"></Rect>,
                <Rect text="Web3"></Rect>,
                <Rect text="Blockchain"></Rect>,
                <Rect text="Web Development"></Rect>,
                <Rect text="IOS Development"></Rect>,
              ]}
            />,
            <VerticalContainer
              title="H2 Accordion Container"
              items={[<Accordion items={accordionData} />]}
            />,
            <HorizontalContainer
              title="H2 Some People"
              items={[
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Smith"}
                  link={"/content"}
                />,
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Doe"}
                  link={"/content"}
                />,
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Black"}
                  link={"/content"}
                />,
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Doe"}
                  link={"/content"}
                />,
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Black"}
                  link={"/content"}
                />,
              ]}
            />,
            <HorizontalContainer
              title="H2 Some People"
              items={[
                <CardMedium
                  image={"/coffeeImagePlaceholder.png"}
                  heading="This is the heading text for the H3 element that will be clamped
              to 5 lines of text. If the text exceeds five rows, it will be
              truncated with an ellipsis."
                  link={"/content"}
                  file={mockLargeFile}
                />,
                <CardMedium
                  image={"/coffeeImagePlaceholder.png"}
                  heading="This is the heading text for the H3 element that will be clamped
              to 5 lines of text. If the text exceeds five rows, it will be
              truncated with an ellipsis."
                  link={"/content"}
                  file={mockPdfFile}
                />,
                <CardMedium
                  image={"/coffeeImagePlaceholder.png"}
                  heading="This is the heading text for the H3 element that will be clamped
              to 5 lines of text. If the text exceeds five rows, it will be
              truncated with an ellipsis."
                  link={"/content"}
                  linkDetails="https://www.eduskunta.fi/FI/sivut/default.aspx"
                />,
                <CardMedium
                  image={"/coffeeImagePlaceholder.png"}
                  heading="This is the heading text for the H3 element that will be clamped
              to 5 lines of text. If the text exceeds five rows, it will be
              truncated with an ellipsis."
                  link={"/content"}
                  file={mockImageFile}
                />,
              ]}
            />,
          ],
        },
      ],
    },
    {
      id: "explore",
      name: "Explore",
      navItems: [
        {
          components: [
            <CardLarge
              image={"/imagePlaceholder.png"}
              heading="H3 title 20 Tips to Improve your Typography Skills"
              text="Body At the core of innovation is creativity. It is the ability to imagine, change, and believe in a different reality."
              link={"/content"}
              file={mockLargeFile}
            />,
          ],
        },
      ],
    },
    {
      id: "marketing",
      name: "Marketing",
      navItems: [
        {
          components: [
            <EmbedYoutube videoId="wDHWNQ88910" />,
            <EmbedSpotify src="https://open.spotify.com/embed/track/21AUuDbg1mKMbaWLfcwUo8?utm_source=generator&theme=0" />,
          ],
        },
      ],
    },
    {
      id: "people",
      name: "People",
      navItems: [
        {
          components: [
            <VerticalContainer
              title="H2 Some People"
              items={[
                <Row
                  image={"/profile-pic.png"}
                  heading="h3 Annu"
                  text="I’m the marketing manager and co-founder of Sisters in Business and also some other text here just so we know it cuts off"
                  link={"/content"}
                  isOnline={true}
                  isMentor={true}
                ></Row>,
                <Row
                  image={"/profile-pic.png"}
                  heading="h3 Annu"
                  text="I’m the marketing manager and co-founder of Sisters in Business and also some other text here just so we know it cuts off"
                  link={"/content"}
                  isOnline={true}
                  isMentor={false}
                ></Row>,
                <Row
                  image={"/profile-pic.png"}
                  heading="h3 Annu"
                  text="I’m the marketing manager and co-founder of Sisters in Business and also some other text here just so we know it cuts off"
                  link={"/content"}
                  isOnline={false}
                  isMentor={true}
                ></Row>,
                <TextRich content={markdownContent} />,
              ]}
            />,
          ],
        },
      ],
    },
    {
      id: "news",
      name: "News",
      navItems: [
        {
          components: [
            <VerticalContainer
              title="H2 Some Title"
              items={[
                <CardWide
                  image={"/imagePlaceholder.png"}
                  heading="h3 The 10 key success factors in implementing design thinking in big organizations"
                  link={"/content"}
                />,
                <CardWide
                  image={"/imagePlaceholder.png"}
                  heading="h3 The 10 key success factors in implementing design thinking in big organizations"
                  link={"/content"}
                />,
                <CardWide
                  image={"/imagePlaceholder.png"}
                  heading="h3 The 10 key success factors in implementing design thinking in big organizations"
                  link={"/content"}
                />,
              ]}
            />,
            <ImageWide
              src={"/imageWide.png"}
              alt="A bokeh picture taken at the event"
              caption="A bokeh picture taken at the event"
            />,
          ],
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col gap-4 px-3 text-default-gray">
      <TabContainer tabs={tabs} />
    </div>
  );
};

export default Content;
