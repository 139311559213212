import { useState } from "react";

interface AccordionItem {
  heading: string;
  content: string;
}

interface AccordionProps {
  items: AccordionItem[];
}

interface RotatingPlusIconProps {
  isOpen: boolean;
}

function RotatingPlusIcon({ isOpen }: RotatingPlusIconProps) {
  return (
    <div
      className={`relative flex h-6 w-6 transform items-center justify-center transition-transform duration-300 ${
        isOpen ? "rotate-45" : ""
      }`}
    >
      <span className="absolute h-[14px] w-0.5 rounded-full bg-default-gray" />
      <span className="absolute h-0.5 w-[14px] rounded-full bg-default-gray" />
    </div>
  );
}

export default function Accordion({ items }: AccordionProps) {
  const [openStates, setOpenStates] = useState<boolean[]>(items.map(() => false));

  const toggleItem = (index: number) => {
    setOpenStates((prevStates) => prevStates.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  return (
    <div className="width-full space-y-2 sm:max-w-96">
      {items.map((item, index) => (
        <div key={index} className="flex flex-col divide-y-[1px] divide-bg-color bg-white pt-5">
          <div
            className="flex cursor-pointer items-center justify-between px-3 pb-5"
            onClick={() => toggleItem(index)}
          >
            <h2 className="text-[17px] font-bold">{item.heading}</h2>
            <RotatingPlusIcon isOpen={openStates[index]} />
          </div>
          <div
            className={`ease-[cubic-bezier(0.4, 0, 0.2, 1)] overflow-hidden transition-[max-height,opacity] duration-700 ${
              openStates[index] ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            <p className="p-3">{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
