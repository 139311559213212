interface RectProps {
  text: string;
}
interface HiddenItemsNumberProps {
  number: number;
  onClick: () => void;
}

export default function Rect({ text }: RectProps) {
  return <div className="flex h-[29px] w-fit items-center bg-white px-3 text-xs">{text}</div>;
}

export function HiddenItemsNumber({ number, onClick }: HiddenItemsNumberProps) {
  return (
    <div
      onClick={onClick}
      className="flex h-[29px] w-fit cursor-pointer items-center bg-[#DBDDDE] px-3 text-xs"
    >{`+${number}`}</div>
  );
}
