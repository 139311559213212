import { useState, useEffect } from "react";
import { ThreadResponse } from "../types";
import { fetchMessages, sendMessage } from "../temp-service/chatService";

export const useChat = () => {
  const [threadResponse, setThreadResponse] = useState<ThreadResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const response = await fetchMessages();
        setThreadResponse(response);
      } catch (err) {
        setError("Failed to fetch messages");
      }
    };

    loadMessages();
  }, []);

  const addMessage = async (content: string) => {
    try {
      const newMessage = await sendMessage(content);
      if (threadResponse) {
        setThreadResponse({
          ...threadResponse,
          messages: [...threadResponse.messages, newMessage],
        });
      }
    } catch (err) {
      setError("Failed to send message");
    }
  };

  const deleteMessage = (id: string) => {
    if (threadResponse) {
      setThreadResponse({
        ...threadResponse,
        messages: threadResponse.messages.filter((msg) => msg.id !== id),
      });
    }
  };

  return { threadResponse, addMessage, deleteMessage, error };
};
