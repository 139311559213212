import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface TextRichProps {
  content: string;
}

interface CodeProps extends React.HTMLProps<HTMLElement> {
  inline?: boolean;
  children?: React.ReactNode;
}

const CodeComponent: React.FC<CodeProps> = ({ inline, children, ...props }) => {
  if (inline) {
    return (
      <code className="rounded bg-gray-100 px-1 py-0.5 text-red-600" {...props}>
        {children}
      </code>
    );
  } else {
    return (
      <pre
        className="overflow-x-auto rounded bg-gray-100 p-2"
        {...(props as React.HTMLProps<HTMLPreElement>)}
      >
        {children}
      </pre>
    );
  }
};

export default function TextRich({ content }: TextRichProps) {
  return (
    <ReactMarkdown
      children={content}
      remarkPlugins={[remarkGfm]}
      components={{
        h2: (props: React.HTMLProps<HTMLHeadingElement>) => (
          <h2
            className="mb-2 text-left text-[20px] text-xl font-extrabold leading-[26px]"
            {...props}
          />
        ),
        p: (props: React.HTMLProps<HTMLParagraphElement>) => (
          <p className="mb-4 text-[17px]" {...props} />
        ),
        a: (props: React.HTMLProps<HTMLAnchorElement>) => (
          <a
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
            {...props}
          />
        ),
        strong: (props: React.HTMLProps<HTMLElement>) => (
          <strong className="font-bold" {...props} />
        ),
        em: (props: React.HTMLProps<HTMLElement>) => <em className="italic" {...props} />,
        code: (props: CodeProps) => (
          <CodeComponent inline className="rounded-sm bg-red-100 p-1 text-red-800" {...props} />
        ),
        ul: (props: React.HTMLProps<HTMLUListElement>) => (
          <ul className="ml-6 list-disc" {...props} />
        ),
        ol: (props: React.HTMLProps<HTMLOListElement>) => {
          const { type, ...restProps } = props;

          // Ensure `type` is one of the allowed values: "1", "A", "I", "a", "i"
          const validTypes: ("1" | "A" | "I" | "a" | "i" | undefined)[] = ["1", "A", "I", "a", "i"];

          // If `type` is a valid string, use it, otherwise default to "1"
          const validType = validTypes.includes(type as "1" | "A" | "I" | "a" | "i")
            ? (type as "1" | "A" | "I" | "a" | "i")
            : "1";

          return <ol className="ml-6 list-decimal" type={validType} {...restProps} />;
        },
        li: (props: React.HTMLProps<HTMLLIElement>) => <li className="mb-1" {...props} />,
      }}
    />
  );
}
