import React from "react";
import FileDetails from "./FileDetails";
import LinkDetails from "./LinkDetails";

interface CardMediumProps {
  image: string;
  heading: string;
  link: string;
  file?: File;
  linkDetails?: string;
}

export default function CardMedium({ image, heading, link, file, linkDetails }: CardMediumProps) {
  return (
    <a
      href={link}
      className="flex h-[300px] w-[205px] flex-col overflow-hidden rounded-bl-xl rounded-br-none rounded-tl-xl rounded-tr-xl no-underline"
    >
      <div className="h-[145px]">
        <img src={image} alt={`${heading} card`} className="h-full w-full object-cover" />
      </div>
      <div className="h-[155px] bg-white p-3">
        <h3 className="line-clamp-4 py-1 text-[17px] font-bold leading-[1.38]">{heading}</h3>
        {file && <FileDetails file={file} />}
        {linkDetails && <LinkDetails url={linkDetails} />}
      </div>
    </a>
  );
}
