/**
 * Extracts the file extension from a given URL or file path.
 * @param url - The URL or file path to extract the extension from.
 * @returns The lowercase file extension without the dot, or an empty string if no extension is found.
 */
export const getFileExtension = (url: string): string => {
  return url.split(".").pop()?.toLowerCase() || "unknown";
};

/**
 * Fetches the file size of a given URL.
 * @param url - The URL to fetch the file size from.
 * @returns The file size in bytes, or null if the size couldn't be determined.
 */
export const getFileSize = async (url: string): Promise<number | null> => {
  try {
    // First, try a HEAD request
    const headResponse = await fetch(url, { method: "HEAD" });
    if (headResponse.ok) {
      const size = parseInt(headResponse.headers.get("Content-Length") || "0", 10);
      if (size) return size;
    }

    // If HEAD fails or doesn't return a size, try a GET request
    const getResponse = await fetch(url);
    if (getResponse.ok) {
      const blob = await getResponse.blob();
      return blob.size;
    }

    // If both methods fail, return null
    return null;
  } catch (error) {
    console.error("Error fetching file size:", error);
    return null;
  }
};
