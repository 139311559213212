import React from "react";
import { Message } from "../../../types";

interface TextMessageProps {
  message: Message;
  isCurrentUser: boolean;
}

const TextMessage: React.FC<TextMessageProps> = ({ message, isCurrentUser }) => {
  return (
    <p className={`mx-2 mt-2 text-sm ${isCurrentUser ? "text-[#1A1A1A]" : "text-[#4F4F4F]"}`}>
      {message.body}
    </p>
  );
};

export default TextMessage;
